// i18next-extract-mark-ns-start pos-systems-for-taxis

import { ContactSalesButton } from "components/ContactSalesButton";
import { Content } from "components/Content";
import { OrderedList } from "components/List";
import "keen-slider/keen-slider.min.css";
import { Section, SectionActions, SectionHeader } from "components/Section";
import { SEO } from "components/SEO";
import { SignUpButton } from "components/SignUpButton";
import { graphql } from "gatsby";
import { Trans, useI18next } from "gatsby-plugin-react-i18next";

import styled from "styled-components";
import { Flex } from "components/Flex";
import React from "react";
import { DownloadOnAppleStore, IosDownloadLink } from "components/DownloadOnAppleStore";
import { AndroidDownloadLink, DownloadOnGooglePlay } from "components/DownloadOnGooglePlay";
import Bold from "components/Bold";
import { MoneiVideoSection } from "components/landings/MoneiVideoSection";
import { CompatibleWith } from "components/monei-pay/CompatibleWith";
import IndexBackground from "components/monei-pay/IndexBackground";
import { BlogLink } from "components/links/Blog";
import { InternalPageLink, MoneiPayQrLink, PricingLink } from "components/links/Pages";
import CardsContainer from "components/CardsContainer";
import { Card } from "components/Card";
import cards from "images/credit-cards.svg";
import mobile_payment from "images/mobile_payment.svg";
import money from "images/money.svg";
import qr from "images/bizum_qr.svg";
import megaphone from "images/megaphone.svg";
import person from "images/person.svg";
import { SignUpLink } from "components/links/Other";
import { Background } from "components/Background";
import { StyledDiv } from "components/StyledSx";
import { QrGroup } from "components/monei-pay/QrGroup";
import { RotatedScreenshots } from "components/monei-pay/RotatedScreenshots";
import { MainTitleContent } from "components/monei-pay/MainTitleContent";
import { SupportLink } from "components/links/Support";
import { GradientBox, GradientCtaImage, GradientSectionDescription } from "components/landings/GradientBox";
import integrated_payments_header_image from "images/integrated_payments_header_image.png";
import { ButtonLink } from "components/Button";

const CardWrapper = styled.div`
  max-width: 255px;
`;

const CardImage = styled.img`
  position: absolute;
`;

const PosSystemsForTaxis: React.FC = () => {
  const {t} = useI18next();
  return (
    <>
      <SEO
        title={t('Taxi POS')}
        description={t(
          'Save time and money and avoid bulky and unreliable taxi POS systems by accepting taxi card payments from your phone with a mobile payment app. Get MONEI Pay ››'
        )}
      />

      <IndexBackground>
        <Content>
          <Section columnLg>
            <MainTitleContent sx={{maxWidth: {all: '590px', lg: '100%'}}}>
              <SectionHeader underline tagName="h1">
                <Trans>POS for taxis: take taxi payments on your phone</Trans>
              </SectionHeader>
              <Trans parent="p">
                In most big cities, taxis are now required to accept credit card payments. You could
                use a POS for taxis, but they come with frequent network issues, high costs, and
                short battery life. Instead, use a mobile payment app to quickly and easily accept
                taxi card payments on your phone.
              </Trans>
              <Trans parent="p">
                <Bold>
                  Save time and money and improve the customer experience with the MONEI Pay app.
                </Bold>
              </Trans>
              <SectionActions>
                <SignUpButton variant="light" style={{marginRight: 15}}>
                  <Trans>Get MONEI Pay</Trans>
                </SignUpButton>
                <ContactSalesButton />
                <CompatibleWith />
              </SectionActions>
            </MainTitleContent>
            <MoneiVideoSection />
          </Section>
        </Content>
      </IndexBackground>
      <Content sx={{marginTop: {md: '20px'}}}>
        <Section sx={{paddingTop: '20px'}}>
          <Flex direction="column" justifyContent="center" alignItems="center">
            <SectionHeader underline style={{marginTop: '10px', textAlign: 'center'}}>
              <Trans>Benefits of accepting taxi payments without a POS</Trans>
            </SectionHeader>
            <p style={{textAlign: 'center'}}>
              <Trans>
                Whether you’re an independent taxi driver, run a taxi business with a fleet of
                drivers, or offer private hires and limousine services, using a mobile payment{' '}
                <BlogLink slug="accept-card-payments-on-phone">
                  app to accept credit card and e-wallet payments
                </BlogLink>{' '}
                from your phone (instead of a taxi POS system) has many advantages.
              </Trans>
            </p>
            <CardsContainer sx={{marginTop: '100px'}}>
              <Card padding="80px 24px 32px 24px">
                <CardImage src={mobile_payment} alt="" style={{top: -45, width: 110}} />
                <CardWrapper>
                  <Trans>
                    Turn your phone into a card terminal in minutes and view transaction history on
                    the go
                  </Trans>
                </CardWrapper>
              </Card>
              <Card padding="80px 32px 32px 32px">
                <CardImage src={cards} alt="" style={{top: -40, width: 80}} />
                <CardWrapper>
                  <Trans>
                    Accept{' '}
                    <InternalPageLink slug="payment-methods/credit-cards">cards</InternalPageLink>{' '}
                    and more{' '}
                    <InternalPageLink slug="payment-methods">payment methods</InternalPageLink> like{' '}
                    <InternalPageLink slug="payment-methods/google-pay">
                      Google Pay
                    </InternalPageLink>
                    ,{' '}
                    <InternalPageLink slug="payment-methods/apple-pay">Apple Pay</InternalPageLink>,
                    and <InternalPageLink slug="payment-methods/bizum">Bizum</InternalPageLink>
                  </Trans>
                </CardWrapper>
              </Card>
              <Card padding="80px 32px 32px 32px">
                <CardImage src={money} alt="" style={{top: -48}} />
                <CardWrapper>
                  <Trans>
                    Save up to <PricingLink>50% on transaction fees</PricingLink> compared to a
                    traditional taxi POS
                  </Trans>
                </CardWrapper>
              </Card>

              <Card padding="80px 32px 32px 32px">
                <CardImage src={qr} alt="" style={{top: -38, width: 90}} />
                <CardWrapper>
                  <Trans>
                    Offer contactless taxi payments with{' '}
                    <MoneiPayQrLink>digital QR codes</MoneiPayQrLink>{' '}
                  </Trans>
                </CardWrapper>
              </Card>

              <Card padding="80px 32px 32px 32px">
                <CardImage src={megaphone} alt="" style={{top: -40, width: 100}} />
                <CardWrapper>
                  <Trans>
                    Build brand awareness with a{' '}
                    <SupportLink slug="/articles/8797620433937">
                      customized QR payment page
                    </SupportLink>
                  </Trans>
                </CardWrapper>
              </Card>
              <Card padding="80px 32px 32px 32px">
                <CardImage src={person} alt="" style={{top: -60, width: 90}} />
                <CardWrapper>
                  <Trans>Send digital receipts via email, SMS, or WhatsApp</Trans>
                </CardWrapper>
              </Card>
            </CardsContainer>
          </Flex>
        </Section>
        <Flex alignItems="center" direction="column">
          <Trans parent="p">
            👇 <Bold>Download your mobile payment app for taxis</Bold> 👇
          </Trans>
          <Flex>
            <div style={{marginRight: '16px'}}>
              <DownloadOnAppleStore />
            </div>
            <DownloadOnGooglePlay />
          </Flex>
        </Flex>
      </Content>

      <Background style={{marginTop: '50px'}}>
        <Content>
          <Section sx={{paddingTop: {sm: '100px'}, alignItems: 'center'}}>
            <div
              style={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'flex-start'
              }}>
              <SectionHeader style={{fontSize: '2rem'}} underline>
                <Trans>How does MONEI Pay for taxis work?</Trans>
              </SectionHeader>
              <Trans parent="p">
                MONEI Pay is a simple yet powerful payment solution for taxi drivers.
              </Trans>
              <OrderedList>
                <Trans parent="li">
                  <SignUpLink>Sign up</SignUpLink> for MONEI
                </Trans>
                <Trans parent="li">
                  <SupportLink slug="/articles/6167527597457">Download</SupportLink> the mobile
                  payment app to your smartphone or tablet
                </Trans>
                <Trans parent="li">
                  <SupportLink slug="/articles/4417709411729">Add users</SupportLink> so your fleet
                  of taxi drivers can accept payments anywhere
                </Trans>
                <Trans parent="li">
                  <SupportLink slug="/articles/4417709618193">
                    Create digital QR code payments
                  </SupportLink>{' '}
                  by entering the purchase amount
                </Trans>
                <Trans parent="li">Passenger scans the QR code to pay</Trans>
                <Trans parent="li">
                  Passenger selects their preferred{' '}
                  <SupportLink slug="/articles/4417709486737">payment method</SupportLink>
                </Trans>
                <Trans parent="li">
                  Passenger finishes the transaction with the tap of a button
                </Trans>
              </OrderedList>
              <SignUpButton variant="dark">
                <Trans>Try MONEI Pay</Trans>
              </SignUpButton>
            </div>
            <RotatedScreenshots />
          </Section>
        </Content>
      </Background>
      <Content>
        <Section centered sx={{paddingTop: '40px'}}>
          <QrGroup />
          <div>
            <SectionHeader underline tagName="h3">
              <Trans>Start accepting taxi payments from any mobile device</Trans>
            </SectionHeader>
            <Trans parent="p">
              Ready to get started? It only takes a few minutes. Open your MONEI account, then
              download the <AndroidDownloadLink>Android</AndroidDownloadLink> or{' '}
              <IosDownloadLink>iOS</IosDownloadLink> mobile payment app on one or many devices to
              accept taxi card payments.
            </Trans>
            <StyledDiv sx={{display: {all: 'inline', sm: 'none'}}}>
              <Trans parent="p">
                👈 <Bold>Scan any QR code to test the payment process.</Bold>
              </Trans>
            </StyledDiv>
            <SignUpButton variant="dark">
              <Trans>Open an Account</Trans>
            </SignUpButton>
          </div>
        </Section>
      </Content>
      <Content>
        <GradientBox wrapperStyle={{overflow: 'visible', marginTop: '60px'}}>
          <SectionHeader tagName="h3" underline style={{color: 'white'}}>
            <Trans>Do you have a platform or marketplace for taxi drivers?</Trans>
          </SectionHeader>
          <GradientSectionDescription>
            <Trans parent="p">
              Use MONEI Connect to integrate payments and help your drivers accept more payment methods in their taxis.
            </Trans>
          </GradientSectionDescription>
          <SectionActions>
            <ButtonLink href="/connect/" variant="dark">
              <Trans>Discover MONEI Connect</Trans>
            </ButtonLink>
          </SectionActions>
          <GradientCtaImage
            sx={{
              maskImage: 'linear-gradient(180deg, #000000 94%, rgba(0, 0, 0, 0) 100%)',
              bottom: '0',
              right: '70px',
              width: '25%',
            }}
            src={integrated_payments_header_image} />
        </GradientBox>
      </Content>
    </>
  );
};

export default PosSystemsForTaxis;

export const query = graphql`
  query($language: String!) {
    locales: allLocale(
      filter: {ns: {in: ["common", "pos-systems-for-taxis"]}, language: {eq: $language}}
    ) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;
